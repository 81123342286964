import React from "react"

// Components
import Seo from "../components/seo"
import Header from "../components/header"
import Hero from "./screens/treatment-in-minutes/hero"
import FormLanding from "./screens/treatment-in-minutes/form-landing"
import BodyLanding from "../components/body-landing"

const TreatmentInMinutes = () => {
	return (
		<main>
			<Header />
			<Hero />
			<FormLanding />
			<BodyLanding landingPage="treatment-in-minutes" />
		</main>
	)
}

export default TreatmentInMinutes

export const Head = () => (
	<Seo
	  title="Precise treatment in minutes"
	  description="On-site PCR replaces send-outs to improve turnaround time and revenue."
	  recaptcha
	/>
  )
